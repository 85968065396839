
	bcore.templates['pos'] = `<div class="pos">
	<div class="row">
		<div class="col-sm-5 pos-bill-wrapper">
			<div class="col-sm-12"><h6 class="form-section-heading uppercase">{{ __("Item Cart") }}</h6></div>
			<div class="pos-bill">
				<div class="item-cart">
					<div class="pos-list-row pos-bill-header text-muted h6">
						<span class="cell subject">
							<!--<input class="list-select-all" type="checkbox" title="{%= __("Select All") %}">-->
							{{ __("Item Name")}}
						</span>
						<span class="cell text-right">{{ __("Quantity") }}</span>
						<span class="cell text-right">{{ __("Discount") }}</span>
						<span class="cell text-right">{{ __("Rate") }}</span>
					</div>
					<div class="item-cart-items">
						<div class="no-items-message text-extra-muted">
							<span class="text-center">
								<i class="fa fa-2x fa-shopping-cart"></i>
								<p>{{ __("Tap items to add them here") }}</p>
							</span>
						</div>
						<div class="items">
						</div>
					</div>
				</div>
			</div>
			<div class="totals-area">
				<div class="pos-list-row net-total-area">
					<div class="cell"></div>
					<div class="cell text-right">{%= __("Net Total") %}</div>
					<div class="cell price-cell bold net-total text-right"></div>
				</div>
				<div class="pos-list-row tax-area">
					<div class="cell"></div>
					<div class="cell text-right">{%= __("Taxes") %}</div>
					<div class="cell price-cell text-right tax-table">
					</div>
				</div>
				{% if(allow_user_to_edit_discount) { %}
					<div class="pos-list-row discount-amount-area">
						<div class="cell"></div>
						<div class="cell text-right">{%= __("Discount") %}</div>
						<div class="cell price-cell discount-field-col">
							<div class="input-group input-group-sm">
								<span class="input-group-addon">%</span>
								<input type="text" class="form-control discount-percentage text-right">
							</div>
							<div class="input-group input-group-sm">
								<span class="input-group-addon">{%= get_currency_symbol(currency) %}</span>
								<input type="text" class="form-control discount-amount text-right" placeholder="{%= 0.00 %}">
							</div>
						</div>
					</div>
				{% } %}
				<div class="pos-list-row grand-total-area collapse-btn" style="border-bottom:1px solid #d1d8dd;">
					<div class="cell">
						<a class="">
							<i class="octicon octicon-chevron-down"></i>
						</a>
					</div>
					<div class="cell text-right bold">{%= __("Grand Total") %}</div>
					<div class="cell price-cell grand-total text-right lead"></div>
				</div>
				<div class="pos-list-row qty-total-area collapse-btn" style="border-bottom:1px solid #d1d8dd;">
					<div class="cell">
						<a class="">
							<i class="octicon octicon-chevron-down"></i>
						</a>
					</div>
					<div class="cell text-right bold">{%= __("Qty Total") %}</div>
					<div class="cell price-cell qty-total text-right lead"></div>
				</div>
			</div>
			<div class="row" style="margin-top: 30px">
				<div class="col-sm-6 selected-item">

				</div>
				<div class="col-xs-6 numeric_keypad hidden-xs" style="display:none">
					{% var chartData = ["Qty", "Disc", "Price"] %} {% for(var i=0; i
					<3; i++) { %} <div class="row text-right">
						{% for(var j=i*3; j
						<(i+1)*3; j++) { %} <button type="button" class="btn btn-default numeric-keypad" val="{{j+1}}">{{j+1}}</button>
							{% } %}
							<button type="button" {% if((!allow_user_to_edit_rate && __(chartData[i]) == __("Price")) || (!allow_user_to_edit_discount && __(chartData[i]) == __("Disc"))) { %} disabled {% } %} id="pos-item-{{ chartData[i].toLowerCase() }}" class="btn text-center btn-default numeric-keypad pos-operation">{{ __(chartData[i]) }}</button>
				</div>
				{% } %}
				<div class="row text-right">
					<button type="button" class="btn btn-default numeric-keypad numeric-del">{{ __("Del") }}</button>
					<button type="button" class="btn btn-default numeric-keypad" val="0">0</button>
					<button type="button" class="btn btn-default numeric-keypad" val=".">.</button>
					<button type="button" class="btn btn-primary numeric-keypad pos-pay">{{ __("Pay") }}</button>
				</div>
			</div>
		</div>
	</div>
	<div class="col-sm-5 list-customers">
		<div class="col-sm-12"><h6 class="form-section-heading uppercase">{{ __("Customers in Queue") }}</h6></div>
		<div class="pos-list-row pos-bill-header">
			<div class="cell subject"><input class="list-select-all" type="checkbox">{{ __("Customer") }}</div>
			<div class="cell text-left">{{ __("Status") }}</div>
			<div class="cell text-right">{{ __("Amount") }}</div>
			<div class="cell text-right">{{ __("Grand Total") }}</div>
		</div>
		<div class="list-customers-table border-left border-right border-bottom">
			<div class="no-items-message text-extra-muted">
				<span class="text-center">
					<i class="fa fa-2x fa-user"></i>
					<p>{{ __("No Customers yet!") }}</p>
				</span>
			</div>
		</div>
	</div>
	<div class="col-sm-7 pos-items-section">
		<div class="col-sm-12"><h6 class="form-section-heading uppercase">{{ __("Stock Items") }}</h6></div>
		<div class="row pos-item-area">

		</div>
		<span id="customer-results" style="color:#68a;"></span>
		<div class="item-list-area">
			<div class="pos-list-row pos-bill-header text-muted h6">
				<div class="cell subject search-item-group">
					<div class="dropdown">
						<a class="text-muted dropdown-toggle" data-toggle="dropdown"><span class="dropdown-text">{{ __("All Item Groups") }}</span><i class="caret"></i></a>
						<ul class="dropdown-menu">
						</ul>
					</div>
				</div>
				<div class="cell search-item"></div>
			</div>
			<div class="app-listing item-list image-view-container">

			</div>
		</div>
	</div>
</div>
`;
