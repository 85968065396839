
	bcore.templates['pos_bill_item'] = `<div class="row pos-bill-row pos-bill-item" data-item-code="{%= item_code %}">
    <div class="col-xs-4"><h6>{%= item_code || "" %}{%= __(item_name) || "" %}</h6></div>
    <div class="col-xs-3">
        <div class="row pos-qty-row">
            <div class="col-xs-2 text-center pos-qty-btn" data-action="decrease-qty"><i class="fa fa-minus text-muted" style="font-size:12px"></i></div>
            <div class="col-xs-8">
				<div>
                    <input type="tel" value="{%= qty %}" class="form-control   pos-item-qty text-right">
                </div>
                {% if(actual_qty != null) { %}
                <div style="margin-top: 5px;" class="text-muted small text-right">
                    {%= __("In Stock: ") %} <span>{%= actual_qty || 0.0 %}</span>
                </div>
                {% } %}
            </div>
            <div class="col-xs-2 text-center pos-qty-btn" data-action="increase-qty"><i class="fa fa-plus text-muted" style="font-size:12px"></i></div>
        </div>
    </div>
    <div class="col-xs-2 text-right">
		<div class="row input-sm">
            <input type="tel" value="{%= discount_percentage %}" class="form-control text-right pos-item-disc">
        </div>
    </div>
    <div class="col-xs-3 text-right">
        <div class="text-muted" style="margin-top: 5px;">
			{% if(enabled) { %}
				<input type="tel" value="{%= rate %}" class="form-control input-sm pos-item-price text-right">
			{% } else { %}
				<h6>{%= format_currency(rate) %}</h6>
			{% } %}
		</div>
		<p><h6>{%= amount %}</h6></p>
    </div>
</div>
`;
