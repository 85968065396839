
	bcore.templates['address_list'] = `<div class="clearfix"></div>
{% for(var i=0, l=addr_list.length; i<l; i++) { %}
<div class="address-box">
	<p class="h6">
		{%= i+1 %}. {%= addr_list[i].address_title %}
		<a href="#Form/Address/{%= encodeURIComponent(addr_list[i].name) %}" class="btn btn-default btn-xs pull-right"
			style="margin-top:-3px; margin-right: -5px;" title="Edit">
			<i class="octicon octicon-pencil"></i></a>

		<a class="btn btn-primary btn-xs pull-right unlink_address" style="margin-top:-3px; margin-right: 10px;"
			data_address_name="{%= addr_list[i].name %}" title="Unlink">
			<i class="fa fa-unlink"></i></a>

		<a class="btn btn-danger btn-xs pull-right delete_address" style="margin-top:-3px; margin-right: 10px;"
			data_address_name="{%= addr_list[i].name %}" title="Delete">
			<i class="octicon octicon-trashcan"></i></a>
	</p>
	<p class="h6">
		{% if(addr_list[i].address_type!="Other") { %}
		<span class="text-muted">Address Type: {%= __(addr_list[i].address_type) %}</span>{% } %}
	</p>
	<p class="h6">
		{% if(addr_list[i].is_primary_address) { %}
		<span class="text-muted">({%= __("Preferred Billing") %})</span>{% } %}
		{% if(addr_list[i].is_shipping_address) { %}
		<span class="text-muted">({%= __("Preferred Shipping") %})</span>{% } %}
		{% if(addr_list[i].disabled) { %}
		<span class="text-muted">({%= __("Disabled") %})</span>{% } %}
	</p>
	<p>{%= addr_list[i].display %}</p>
</div>
{% } %}
{% if(!addr_list.length) { %}
<p class="text-muted small">{%= __("No address added yet.") %}</p>
{% } %}
<p>
	<button class="btn btn-xs btn-default btn-address">{{ __("New Address") }}</button>
	<button class="btn btn-xs btn-default btn-address-link">
		{{ __("Link Address") }}</button>
</p>`;
