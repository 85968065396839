
	bcore.templates['pos_item'] = `<div class="pos-item-wrapper image-view-item" data-item-code="{{item_code}}">
	<div class="image-view-header doclist-row">
		<div class="list-value">
			<a class="grey list-id" data-name="{{item_code}}" title="{{ item_name || item_code}}">{{item_name || item_code}}<br>({{ __(item_stock) }})</a>
		</div>
	</div>
	<div class="image-view-body">
		<a  data-item-code="{{ item_code }}"
			title="{{ item_name || item_code }}"
		>
			<div class="image-field"
				style="
				{% if (!item_image) { %}
					background-color: #fafbfc;
				{% } %}
				border: 0px;"
			>
				{% if (!item_image) { %}
				<span class="placeholder-text">
					{%= bcore.get_abbr(item_name || item_code) %}
				</span>
				{% } %}
				{% if (item_image) { %}
				<img src="{{ item_image }}" alt="{{item_name || item_code}}">
				{% } %}
			</div>
			<span class="price-info">
				{{item_price}} / {{item_uom}}
			</span>
		</a>
	</div>
</div>`;
