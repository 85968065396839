import "./js/conf.js"
import "./js/utils.js"
import "./js/compliance.js"
import "./js/queries.js"
import "./js/sms_manager.js"
import "./js/utils/party.js"
import "./js/templates/address_list.html"
import "./js/templates/contact_list.html"
import "./js/controllers/stock_controller.js"
import "./js/payment/payments.js"
import "./js/controllers/taxes_and_totals.js"
import "./js/controllers/transaction.js"
import "./js/pos/pos.html"
import "./js/pos/pos_bill_item.html"
import "./js/pos/pos_bill_item_new.html"
import "./js/pos/pos_selected_item.html"
import "./js/pos/pos_item.html"
import "./js/pos/pos_tax_row.html"
import "./js/pos/customer_toolbar.html"
import "./js/pos/pos_invoice_list.html"
import "./js/payment/pos_payment.html"
import "./js/payment/payment_details.html"
import "./js/templates/item_selector.html"
import "./js/templates/employees_to_mark_attendance.html"
import "./js/utils/item_selector.js"
import "./js/help_links.js"
import "./js/agriculture/ternary_plot.js"
import "./js/templates/item_quick_entry.html"
import "./js/utils/item_quick_entry.js"
import "./js/utils/customer_quick_entry.js"
import "./js/education/student_button.html"
import "./js/education/assessment_result_tool.html"
import "./js/hub/hub_factory.js"
import "./js/call_popup/call_popup.js"
import "./js/utils/dimension_tree_filter.js"
import "./js/utils/card_validator.js"
import "./js/utils/bloombrackets_widget.js"