
	bcore.templates['contact_list'] = `<div class="clearfix"></div>
{% for(var i=0, l=contact_list.length; i<l; i++) { %}
<div class="contact-box">
	<p class="h6">
		{%= contact_list[i].first_name %} {%= contact_list[i].last_name %}
		{% if(contact_list[i].is_primary_contact) { %}
		<span class="text-muted">({%= __("Primary") %})</span>
		{% } %}

		<a href="#Form/Contact/{%= encodeURIComponent(contact_list[i].name) %}" class="btn btn-xs btn-default pull-right"
			style="margin-top:-3px; margin-right: -5px;" title="Edit">
			<i class="octicon octicon-pencil"></i></a>

		<a class="btn btn-primary btn-xs pull-right unlink_contact" style="margin-top:-3px; margin-right: 10px;"
			data_contact_name="{%= contact_list[i].name %}" title="Unlink">
			<i class="fa fa-unlink"></i></a>

		<a class="btn btn-danger btn-xs pull-right delete_contact" style="margin-top:-3px; margin-right: 10px;"
			data_contact_name="{%= contact_list[i].name %}" title="Delete">
			<i class="octicon octicon-trashcan"></i></a>
	</p>
	{% if (contact_list[i].phones || contact_list[i].email_ids) { %}
	<p>
		{% if(contact_list[i].phone) { %}
		<strong>{%= __("Phone") %}:</strong> {%= contact_list[i].phone %}<span class="text-muted"> ({%= __("Primary") %})</span><br>
		{% endif %}
		{% if(contact_list[i].mobile_no) { %}
		<strong>{%= __("Mobile No") %}:</strong> {%= contact_list[i].mobile_no %}<span class="text-muted"> ({%= __("Primary") %})</span><br>
		{% endif %}
		{% if(contact_list[i].phone_nos) { %}
		{% for(var j=0, k=contact_list[i].phone_nos.length; j<k; j++) { %}
		<strong>{%= __("Phone") %}:</strong> {%= contact_list[i].phone_nos[j].phone %}<br>
		{% } %}
		{% endif %}
	</p>

	<p>
		{% if(contact_list[i].email_id) { %}
		<strong>{%= __("Email") %}: </strong>{%= contact_list[i].email_id %}<span class="text-muted"> ({%= __("Primary") %})</span><br>
		{% endif %}
		{% if(contact_list[i].email_ids) { %}
		{% for(var j=0, k=contact_list[i].email_ids.length; j<k; j++) { %}
		<strong>{%= __("Email") %}:</strong> {%= contact_list[i].email_ids[j].email_id %}<br>
		{% } %}
		{% endif %}
	</p>

	<p>
		{% if(contact_list[i].designation){ %}
			<strong>{%= __("Designation") %}:</strong> {%= contact_list[i].designation %}<br>
		{% } %}
	</p> 

	{% endif %}
	<p>
		{% if (contact_list[i].address) { %}
		{%= __("Address") %}: {%= contact_list[i].address %}<br>
		{% endif %}
	</p>
</div>
{% } %}
{% if(!contact_list.length) { %}
<p class="text-muted small">{%= __("No contacts added yet.") %}</p>
{% } %}
<p><button class="btn btn-xs btn-default btn-contact">
		{{ __("New Contact") }}</button>
<button class="btn btn-xs btn-default btn-contact-link">
		{{ __("Link Contact") }}</button>
</p>`;
